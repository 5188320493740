<template>
  <div>
    <iframe
      src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=531d75f5-d4d6-4bf0-8eda-5ae72177cf45&accessTicket=280b888d-048f-4239-aed2-343ff964653d&dd_orientation=auto"
      class="fullscreen-iframe"
    />
  </div>
</template>

<script>
export default {
  name: 'FullscreenIframe'
}
</script>

<style scoped>
.fullscreen-iframe {
  border: none;          /* Removes the border */
  width: 100%;         /* Full viewport width */
  height: calc(100vh - 100px);        /* Full viewport height */
  top: 0;              /* Aligns to the top */
  left: 0;             /* Aligns to the left */
}
</style>
